<template>
    <div class="call-to-action">
        <div
            v-if="!!$slots.title"
            class="call-to-action__title"
        >
            <slot name="title" />
        </div>

        <p
            v-if="$slots.description"
            class="call-to-action__description"
        >
            <slot name="description" />
        </p>

        <BaseButton
            v-if="$slots.cta"
            class="base-button call-to-action__button"
            :class="{
                'base-button--large': !hideIcon
            }"
            @click="openBookingOverlay({ location: selectedLocation,parentComponent })"
        >
            <slot name="cta" />
            <BaseIcon
                v-if="!hideIcon"
                icon="calendar"
            />
        </BaseButton>
    </div>
</template>

<script setup>
import BaseButton from '@/components/BaseButton.vue';
import BaseIcon from '@/components/BaseIcon.vue';

defineProps({
    selectedLocation: {
        type: Object,
        required: false,
        default: () => null
    },
    hideIcon: {
        type: Boolean,
        required: false,
        default: false
    },
    parentComponent: {
        type: String,
        default: null
    }
});

import { useBookingOverlayStore } from '@/store/booking-overlay';
const { openBookingOverlay } = useBookingOverlayStore();

const route = useRoute();
</script>

<style lang="less">
.call-to-action {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
    align-items: flex-start;

    @media @q-sm-min {
        gap: var(--spacing-lg);
    }
}

.call-to-action__title {
    align-self: stretch;
}

.call-to-action__description {
    .typography-text-sm();

    margin: 0;
}

.call-to-action__button {
    flex: 0 1 auto;
}
</style>
